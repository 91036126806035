// App.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./components/Home";
import ContactUs from "./components/ContactUs";
import AboutUs from "./components/AboutUs";
import ProductShow from "./components/ProductShow";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Innovation from "./components/Innovation";
import Support from "./components/Support";
import ThermalCamera from "./components/ThermalCamera";
import CloudXVR from "./components/CloudXVR";
import ANPRCamera from "./components/ANPRCamera";
import _4KCamera from "./components/_4KCamera";
import EdgeAICamera from "./components/EdgeAICamera";
import _4GCamera from "./components/_4GCamera";
import Downloads from "./components/Downloads";
import Datasheet from "./components/Datasheet";
import Feedback from "./components/Feedback";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import WarrantyService from "./components/WarrantyService";
import WarrantyPolicy from "./components/WarrantyPolicy";
import Blogs from "./components/Blogs";
import Blog1 from "./components/Blog1";
import OtpGenerator from "./components/OtpGenerator";
import AddBlogs from "./components/AddBlogs";
import ViewBlogs from "./components/ViewBlogs";
import NotFound from "./components/NotFound";
import Robotics from "./components/Robotics";
import PublicSafety from "./components/PublicSafety";
import Trafic from "./components/Trafic";
import CrowdControl from "./components/CrowdControl";
import SmartCities from "./components/SmartCities";
import Remote from "./components/Remote";
import Education from "./components/Education";
import Hospital from "./components/Hospital";
import PublicTransport from "./components/PublicTransport";
import Retail from "./components/Retail";
import SmartCity from "./components/SmartCity";
import BankFinance from "./components/BankFinance";
import HighTraffic from "./components/HighTraffic";
import AutoplayCarousel from "./components/AutoplayCarousel";
import Sustainability from "./components/Sustainability";
import _360Approach from "./components/_360Approach";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./index.css";
import FutureGrowth from "./components/FutureGrowth";
import Compliance from "./components/Compliance";
import CyberSecurity from "./components/CyberSecurity";
import Manufacturing from "./components/Manufacturing";
import RSeries from "./components/RSeries";
import ProdEngineering from "./components/ProdEngineering";
import CloudApplication from "./components/CloudApplication";
import PartnersPage from "./components/PartnersPage";
import SSeries from "./components/SSeries";
import ThankYouPage from "./components/Thanks";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route path="/innovation" element={<Innovation />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/support" element={<Support />} />

        {/* product routes */}
        <Route
          path="/adiance-thermal-camera-f"
          element={
            <ProductShow
              productTitle={
                "ADIANCE CLOUD BASED THERMAL CAMERA – (VM-72F210AC)"
              }
            />
          }
        />
        <Route
          path="/adiance-thermal-camera-n"
          element={
            <ProductShow
              productTitle={
                "ADIANCE CLOUD BASED THERMAL CAMERA – (VM-72N210AC)"
              }
            />
          }
        />
        <Route
          path="/adiance-thermal-camera-l"
          element={
            <ProductShow
              productTitle={
                "ADIANCE CLOUD BASED THERMAL CAMERA – (VM-72L210AC)"
              }
            />
          }
        />
        <Route
          path="/edge-ai-based-ptz-anpr-bullet-camera"
          element={
            <ProductShow
              productTitle={"Edge AI Based PTZ ANPR Bullet Camera"}
              imgIdx={0}
            />
          }
        />
        <Route
          path="/4K-Bullet-ANPR-PTZ-Camera"
          element={
            <ProductShow
              productTitle={"Edge AI Based PTZ ANPR Bullet Camera"}
              imgIdx={1}
            />
          }
        />
        <Route
          path="/4K-Face-Recognition-Camera"
          element={
            <ProductShow
              productTitle={"Edge AI Based Face Recognition Dome Camera"}
              imgIdx={0}
            />
          }
        />
        <Route
          path="/Edge-AI-Based-Object-n-Face-Detection-Cameras"
          element={
            <ProductShow
              productTitle={"Edge AI Based Object & Face Detection Cameras"}
              imgIdx={0}
            />
          }
        />
        <Route
          path="/edge-ai-based-ptz-anpr-bullet-camera-3"
          element={
            <ProductShow
              productTitle={"Edge AI Based PTZ ANPR Bullet Camera"}
              imgIdx={2}
            />
          }
        />
        <Route
          path="/edge-ai-based-recognition-camera"
          element={
            <ProductShow
              productTitle={"Edge AI Based Face Recognition Dome Camera"}
              imgIdx={1}
            />
          }
        />
        <Route
          path="/Edge-AI-Based-Object-n-Face-Detection-Camera"
          element={
            <ProductShow
              productTitle={"Edge AI Based Object & Face Detection Cameras"}
              imgIdx={1}
            />
          }
        />
        <Route
          path="/4G-Dome-PTZ-Camera"
          element={
            <ProductShow
              productTitle={"AMBICAM 4G Dome PTZ Camera (VM-72BPTZ410AC)"}
            />
          }
        />
        <Route
          path="/4G-Mini-Bullet-Camera"
          element={
            <ProductShow
              productTitle={"AMBICAM 4G Mini Bullet Camera (VM-72H4G110AC)"}
            />
          }
        />
        <Route
          path="/4G-Dome-Camera"
          element={
            <ProductShow
              productTitle={"AMBICAM 4G Dome Camera (VM-72AD4G210C)"}
            />
          }
        />
        <Route
          path="/H265-4G-Dome-PTZ-Camera"
          element={
            <ProductShow
              productTitle={
                "AMBICAM H.265+ 4G Dome PTZ Camera (VM-72BPTZ410AC)"
              }
            />
          }
        />
        <Route
          path="/cloudXVR"
          element={
            <ProductShow
              productTitle={"ADIANCE 8-16 Channel XVR – VM-72XVR816"}
            />
          }
        />
        <Route
          path="/5g-edge-ai-camera-s-series-surveillance"
          element={
            <SSeries
              productTitle={"The World's First 5G Enabled Edge AI Camera "}
            />
          }
        />

        <Route
          path="/3MP-4G-Bullet-All-Time-Color-Camera-A-Series"
          element={
            <ProductShow
              productTitle={"3MP 4G Bullet All Time Color Camera - A Series"}
            />
          }
        />
        <Route path="/R-Series" element={<RSeries title={"R Series"} />} />
        <Route path="/H-Series" element={<RSeries title={"H Series"} />} />

        <Route path="/thermalCamera" element={<ThermalCamera />} />
        <Route path="/CloudXVR" element={<CloudXVR />} />
        <Route path="/ANPRCamera" element={<ANPRCamera />} />
        <Route path="/4KCamera" element={<_4KCamera />} />
        <Route path="/EdgeAICamera" element={<EdgeAICamera />} />
        <Route path="/4GCamera" element={<_4GCamera />} />

        <Route path="/downloads" element={<Downloads />} />
        <Route path="/datasheet" element={<Datasheet />} />
        <Route path="/feedback" element={<Feedback />} />

        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/warranty-service" element={<WarrantyService />} />
        <Route path="/warranty-policy" element={<WarrantyPolicy />} />
        <Route path="/blogs" element={<Blogs />} />

        {/*         
        <Route path="/blog2" element={<Blog2/>} />
        <Route path="/blog3" element={<Blog3/>} />
        <Route path="/blog4" element={<Blog4/>} />
        <Route path="/blog5" element={<Blog5/>} />
      <Route path="/blog6" element={<Blog6/>} /> */}

        {/* <Route path="/blog/:id" render={(props) => <Bloe-Camera" element={<ProductShow productTitle={"AMBICAM 4G Dome Camera (VM-72AD4G210C)"} />} />
        <Route path="/H265-4G-Dome-PTZ-Camera" element={<ProductShow productTitle={"AMBICAM H.265+ 4G Dome PTZ Camera (VM-72BPTZ410AC)"} />} />
        <Route path="/cloudXVR" element={<ProductShow productTitle={"ADIANCE 8-16 Channel XVR – VM-72XVR816"} />} />
        <Route path="/AI-3MP-Nexgen-4G-PT-Camera-S-Series" element={<ProductShow productTitle={"AI 3MP Nexgen 4G PT Camera - S Series"} />} />
        
        <Route path="/3MP-4G-Bullet-All-Time-Color-Camera-A-Series" element={<ProductShow productTitle={"3MP 4G Bullet All Time Color Camera - A Series"} />} />
        <Route path='/R-Series' element={<RSeries title={"R Series"}/>} />
        <Route path='/H-Series' element={<RSeries title={"H Series"}/>} />
        
        <Rg1 id={props.match.params.id} />} /> */}
        <Route path="/blog/:id" element={<Blog1 />} />
        <Route path="/view-blogs" element={<ViewBlogs />} />

        {/* <Route path="/blog/:id" component={Blog1} /> */}

        {/* Route for individual blog pages */}

        <Route path="/Otp-Generator" element={<OtpGenerator />} />
        <Route path="/add-blogs" element={<AddBlogs />} />
        <Route path="/Robotics" element={<Robotics />} />

        <Route path="/Public-safety" element={<PublicSafety />} />
        <Route path="/Trafic-Management" element={<Trafic />} />
        <Route path="/Crowd-Control" element={<CrowdControl />} />
        <Route path="/Smart-Cities" element={<SmartCities />} />
        <Route path="/Remote-Security" element={<Remote />} />
        <Route path="/Education" element={<Education />} />
        <Route path="/Healthcare" element={<Hospital />} />
        <Route path="/Public-Transport" element={<PublicTransport />} />
        <Route path="/Retail" element={<Retail />} />
        <Route path="/Smart-Safe-City" element={<SmartCity />} />
        <Route path="/bank-finance" element={<BankFinance />} />
        <Route path="/High-Traffic" element={<HighTraffic />} />

        <Route path="/autoplay" element={<AutoplayCarousel />} />
        <Route path="/Sustainability" element={<Sustainability />} />
        <Route path="/360-approach" element={<_360Approach />} />
        <Route path="/future-and-growth" element={<FutureGrowth />} />
        <Route path="/Compliance" element={<Compliance />} />
        <Route path="/CyberSecurity" element={<CyberSecurity />} />
        <Route path="/Manufacturing" element={<Manufacturing />} />
        <Route path="/Product-Engineering" element={<ProdEngineering />} />
        <Route path="/Cloud-Application" element={<CloudApplication />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />

        <Route path="/Partners" element={<PartnersPage />} />

        <Route path="/thanks" element={<ThankYouPage />} />
      </Routes>
    </Router>
  );
}

export default App;
