import React from "react";
import Header from "./Header";
import NavHeader from "./NavHeader";
import ContactForm from "./ContactForm";
import ContactMidSection from "./ContactMidSection";
import Footer from "./Footer";
import ContactZoho from "./ContactZoho";
import { Helmet } from "react-helmet";

export default function () {
  return (
    <div>
      <Helmet>
        <title>Contact us | CCTV Manufacturers, Camera Makers</title>
        <meta
          name="description"
          content="Contact us | CCTV Manufacturers, Camera Makers, Get in touch with our company through mails, phone and providing address."
        />
      </Helmet>
      <Header />
      <NavHeader text={"Contact Us"} />

      <ContactZoho />

      <ContactMidSection />
      <Footer />
    </div>
  );
}
