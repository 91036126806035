import React from "react";
import Header from "./Header";
import ImageSlider from "./ImageSlider";
import ProductSlider from "./ProductSlider";
import MidSection from "./MidSection";
import Footer from "./Footer";
import AboutSlider from "./AboutSlider";
import { Helmet } from "react-helmet";
import AutoplayCarousel from "./AutoplayCarousel";
import IconsWithTitles from "./IconsWithTitles";
import IfsecModal from "./IfsecModal";

function Home() {
  return (
    <div>
      <Helmet>
        <title>Adiance: The Best Security Camera Manufacturer</title>
        <meta
          name="description"
          content="Adiance is best security camera manufacturers & supplier company which provides in customization of CCTV camera according to the client’s requirements"
        />
        <meta
          name="google-site-verification"
          content="ToZv5ontdwBZWArKbClqliVv4Zzduzs5-CbhZxgxaE4"
        />
      </Helmet>
      <Header />
      {/* <IfsecModal /> */}
      <ImageSlider />
      {/* <ProductSlider /> */}
      <IconsWithTitles />
      <MidSection />
      <AutoplayCarousel />
      <Footer />
    </div>
  );
}

export default Home;
