import React, { useEffect } from "react";
import "./contact-zoho/css/form.css";

const ZohoForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "./contact-zoho/js/validation.js"; // Ensure this path matches your file location
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // Cleanup script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `
          <div id="zoho-form-container">
           <body class="zf-backgroundBg">
    <!-- Change or deletion of the name attributes in the input tag will lead to empty values on record submission-->
    <div class="zf-templateWidth">
      <form
        action="https://forms.zohopublic.in/vmukti1/form/AdianceContactForm/formperma/8t92GFm1RxWC5U_35QhFuYTpyT9m-LZ5c0l_1Fl6jiY/htmlRecords/submit"
        name="form"
        method="POST"
        onSubmit='javascript:document.charset="UTF-8"; return zf_ValidateAndSubmit();'
        accept-charset="UTF-8"
        enctype="multipart/form-data"
        id="form"
      >
        <input
          type="hidden"
          name="zf_referrer_name"
          value=""
        /><!-- To Track referrals , place the referrer name within the " " in the above hidden input field -->
        <input
          type="hidden"
          name="zf_redirect_url"
          value=""
        /><!-- To redirect to a specific page after record submission , place the respective url within the " " in the above hidden input field -->
        <input
          type="hidden"
          name="zc_gad"
          value=""
        /><!-- If GCLID is enabled in Zoho CRM Integration, click details of AdWords Ads will be pushed to Zoho CRM -->
        <div class="zf-templateWrapper">
          <!---------template Header Starts Here---------->
          <ul class="zf-tempHeadBdr">
            <li class="zf-tempHeadContBdr">
              <h2 class="zf-frmTitle"><em>Share us your Requirements</em></h2>
              <p class="zf-frmDesc"></p>
              <div class="zf-clearBoth"></div>
            </li>
          </ul>
          <!---------template Header Ends Here---------->
          <!---------template Container Starts Here---------->
          <div class="zf-subContWrap zf-topAlign">
            <ul>
              <!---------Single Line Starts Here---------->
              <li class="zf-tempFrmWrapper zf-medium">
                <label class="zf-labelName">
                  Name
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv">
                  <span>
                    <input
                      type="text"
                      name="SingleLine"
                      checktype="c1"
                      value=""
                      maxlength="255"
                      fieldType="1"
                      placeholder=""
                  /></span>
                  <p
                    id="SingleLine_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Single Line Ends Here---------->
              <!---------Single Line Starts Here---------->
              <li class="zf-tempFrmWrapper zf-medium">
                <label class="zf-labelName">
                  Company
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv">
                  <span>
                    <input
                      type="text"
                      name="SingleLine1"
                      checktype="c1"
                      value=""
                      maxlength="255"
                      fieldType="1"
                      placeholder=""
                  /></span>
                  <p
                    id="SingleLine1_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Single Line Ends Here---------->
              <!---------Phone Starts Here---------->
              <li class="zf-tempFrmWrapper zf-medium">
                <label class="zf-labelName">
                  Phone
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv zf-phonefld">
                  <div class="zf-phwrapper zf-phNumber">
                    <span>
                      <input
                        type="text"
                        compname="PhoneNumber"
                        name="PhoneNumber_countrycode"
                        maxlength="20"
                        checktype="c7"
                        value=""
                        phoneFormat="1"
                        isCountryCodeEnabled="false"
                        fieldType="11"
                        id="international_PhoneNumber_countrycode"
                        valType="number"
                        phoneFormatType="1"
                        placeholder=""
                      />
                      <label>Number</label>
                    </span>
                    <div class="zf-clearBoth"></div>
                  </div>
                  <p
                    id="PhoneNumber_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Phone Ends Here---------->
              <!---------Email Starts Here---------->
              <li class="zf-tempFrmWrapper zf-medium">
                <label class="zf-labelName">
                  Email
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv">
                  <span>
                    <input
                      fieldType="9"
                      type="text"
                      maxlength="255"
                      name="Email"
                      checktype="c5"
                      value=""
                      placeholder=""
                  /></span>
                  <p
                    id="Email_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Email Ends Here---------->
              <!---------Single Line Starts Here---------->
              <li class="zf-tempFrmWrapper zf-medium">
                <label class="zf-labelName">
                  City
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv">
                  <span>
                    <input
                      type="text"
                      name="SingleLine2"
                      checktype="c1"
                      value=""
                      maxlength="255"
                      fieldType="1"
                      placeholder=""
                  /></span>
                  <p
                    id="SingleLine2_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Single Line Ends Here---------->
              <!---------Dropdown Starts Here---------->
              <li class="zf-tempFrmWrapper zf-medium">
                <label class="zf-labelName">
                  Business Type
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv">
                  <select class="zf-form-sBox" name="Dropdown" checktype="c1">
                    <option selected="true" value="-Select-">-Select-</option>
                    <option value="End&#x20;User">End User</option>
                    <option value="Government">Government</option>
                    <option value="Enterprise">Enterprise</option>
                    <option value="Distributor">Distributor</option>
                    <option value="Dealer">Dealer</option>
                    <option value="Consultant">Consultant</option>
                    <option value="OEM">OEM</option>
                    <option value="Reseller">Reseller</option>
                    <option value="System&#x20;Integrator">
                      System Integrator
                    </option>
                  </select>
                  <p
                    id="Dropdown_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Dropdown Ends Here---------->
              <!---------Dropdown Starts Here---------->
              <li class="zf-tempFrmWrapper zf-medium">
                <label class="zf-labelName">
                  Enquiry For
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv">
                  <select class="zf-form-sBox" name="Dropdown1" checktype="c1">
                    <option selected="true" value="-Select-">-Select-</option>
                    <option value="WiFi&#x20;Camera">WiFi Camera</option>
                    <option value="4G&#x20;Camera">4G Camera</option>
                    <option value="Thermal&#x20;Camera">Thermal Camera</option>
                    <option value="Edge&#x20;AI&#x20;Camera">
                      Edge AI Camera
                    </option>
                    <option value="VMS&#x20;VAS">VMS VAS</option>
                    <option value="Media&#x20;Server">Media Server</option>
                    <option value="Government&#x20;Projects">
                      Government Projects
                    </option>
                    <option value="Solutions">Solutions</option>
                    <option value="Others">Others</option>
                  </select>
                  <p
                    id="Dropdown1_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Dropdown Ends Here---------->
              <!---------Multiple Line Starts Here---------->
              <li class="zf-tempFrmWrapper zf-large">
                <label class="zf-labelName"> Description </label>
                <div class="zf-tempContDiv">
                  <span>
                    <textarea
                      name="MultiLine"
                      checktype="c1"
                      maxlength="65535"
                      placeholder=""
                    ></textarea>
                  </span>
                  <p
                    id="MultiLine_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Multiple Line Ends Here---------->
              <!---------Single Line Starts Here---------->
              <li class="zf-tempFrmWrapper zf-large">
                <label class="zf-labelName">
                  Business Unit
                  <em class="zf-important">*</em>
                </label>
                <div class="zf-tempContDiv">
                  <span>
                    <input
                      type="text"
                      name="SingleLine3"
                      checktype="c1"
                      value="Adiance Technologies Pvt Ltd"
                      maxlength="255"
                      fieldType="1"
                      placeholder=""
                  /></span>
                  <p
                    id="SingleLine3_error"
                    class="zf-errorMessage"
                    style="display: none"
                  >
                    Invalid value
                  </p>
                </div>
                <div class="zf-clearBoth"></div>
              </li>
              <!---------Single Line Ends Here---------->
            </ul>
          </div>
          <!---------template Container Starts Here---------->
          <ul>
            <li class="zf-fmFooter">
              <button class="zf-submitColor">Submit</button>
            </li>
          </ul>
        </div>
        <!-- 'zf-templateWrapper' ends -->
      </form>
    </div>
    <!-- 'zf-templateWidth' ends -->
    <script type="text/javascript">
      var zf_DateRegex = new RegExp(
        "^(([0][1-9])|([1-2][0-9])|([3][0-1]))[-](Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[-](?:(?:19|20)[0-9]{2})$"
      );
      var zf_MonthYearRegex = new RegExp(
        "^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)[-](?:(?:19|20)[0-9]{2})$"
      );
      var zf_MandArray = [
        "SingleLine",
        "SingleLine1",
        "PhoneNumber_countrycode",
        "Email",
        "SingleLine2",
        "Dropdown",
        "Dropdown1",
        "SingleLine3",
      ];
      var zf_FieldArray = [
        "SingleLine",
        "SingleLine1",
        "PhoneNumber_countrycode",
        "Email",
        "SingleLine2",
        "Dropdown",
        "Dropdown1",
        "MultiLine",
        "SingleLine3",
      ];
      var isSalesIQIntegrationEnabled = false;
      var salesIQFieldsArray = [];
    </script>
  </body>
          </div>
        `,
      }}
    />
  );
};

export default ZohoForm;
